<template>
  <div class="car-card">
    <div class="car-card-image">
      <img :src="require(`@/assets/cars/${car.image}`)" :alt="car.title" />
    </div>
    <div class="car-card-details-line">
      <ul>
        <li class="highlighted text-center">
          <span class="value text-uppercase">{{ car.title }} ({{ car.color }})</span>
        </li>
        <li>
          <span class="key">Életkor</span>
          <span class="value">{{ car.specifications.age.min }} - {{ car.specifications.age.max }} év</span>
        </li>
        <li>
          <span class="key">Terhelhetőség</span>
          <span class="value">{{ car.specifications.maxCapacity }} kg</span>
        </li>
        <li>
          <span class="key">Sebesség</span>
          <span class="value">{{ car.specifications.speed.min }} - {{ car.specifications.speed.max }} km / h</span>
        </li>
        <li>
          <span class="key">Távirányító</span>
          <span class="value">{{ car.equipments.indexOf("remoteControl") >= 0 ? "Van" : "Nincs" }}</span>
        </li>
      </ul>
    </div>
    <div class="car-card-back">
      <div class="car-card-excerpt">{{ car.excerpt }}</div>
      <div class="car-card-action-buttons">
        <BtnMore :to="`/jarmuveink/${car.slug}`" />
      </div>
    </div>
  </div>
</template>
<script>
import BtnMore from "@/components/units/buttons/BtnMore";

export default {
  name: "CarCard",
  components: { BtnMore },
  props: {
    car: Object,
  },
};
</script>

<style scoped lang="scss">
.car-card {
  position: relative;
  display: block;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  height: $card-image-max-height;
  overflow: hidden;

  &-image {
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
      border-radius: $border-radius;
    }
  }

  &-details-line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba($gray-900, 0.7);
    padding: calc(#{$spacer} / 2) $spacer;
    transition: $transition-base;

    ul {
      display: flex;
      flex-direction: row;
      list-style: none;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
      row-gap: calc(#{$spacer} / 2);
      column-gap: $spacer;

      li {
        display: flex;
        flex-direction: column;

        .key {
          @include set-font-style($font-size-level-1, $font-weight-normal, $white, uppercase);
        }

        .value {
          @include set-font-style($font-size-level-3, $font-weight-bolder, $white);
        }

        &.highlighted {
          flex-basis: 100%;
        }
      }
    }
  }

  &-back {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 100%;
    padding: $spacer;
    background-color: rgba($gray-900, 0.7);
    z-index: $zindex-dropdown;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $white;
    transition: $transition-base;

    .car-card-excerpt {
      font-size: $font-size-level-3;
      margin-bottom: $spacer;
    }
  }

  &:hover {
    .car-card-details-line {
      transition: $transition-base;
      bottom: 100%;
    }

    .car-card-back {
      transition: $transition-base;
      top: 0;
    }
  }
}
</style>
