<template>
  <PrimaryMenu />

  <Header title="Szolgáltatásaink / Áraink" />

  <SectionBlock>
    <SectionTitle :title="`Bérlés ${service.title}`" />
    <SectionContent>
      <div v-html="service.mainContent" />

      <BRow gutter-x="4" gutter-y="4" class="mt-1 mb-4" :key="service.id" align-v="stretch" align-h="center">
        <BCol
          :sm="getGridSm(service.packages.length, 3, 4)"
          v-for="servicePackage in service.packages"
          :key="servicePackage.id"
        >
          <ServicePackageCard :servicePackage="servicePackage" />
        </BCol>
      </BRow>

      <div v-html="service.subContent" />
    </SectionContent>
  </SectionBlock>

  <SectionCars class="bg-light" />

  <SectionServices :excludeServices="services" :key="service.id" />

  <SectionContact />

  <Footer />
</template>

<script>
import PrimaryMenu from "@/components/layout/PrimaryMenu";
import Header from "@/components/layout/Header";
import SectionBlock from "@/components/layout/sections/parts/SectionBlock";
import SectionTitle from "@/components/layout/sections/parts/SectionTitle";
import SectionContent from "@/components/layout/sections/parts/SectionContent";
import SectionContact from "@/components/layout/sections/SectionContact";
import Footer from "@/components/layout/Footer";
import ServicePackageCard from "@/components/units/cards/ServicePackageCard";
// import SectionGallery from "@/components/layout/sections/SectionGallery";
import SectionServices from "@/components/layout/sections/SectionServices";
import SectionCars from "@/components/layout/sections/SectionCars";

export default {
  name: "Service",
  title: "Szolgáltatásaink / Áraink",
  components: {
    SectionCars,
    SectionServices,
    // SectionGallery,
    ServicePackageCard,
    PrimaryMenu,
    Header,
    SectionBlock,
    SectionContent,
    SectionTitle,
    SectionContact,
    Footer,
  },

  data() {
    return {
      service: null,
      // gridSm: 4,
      // excludeServices: null,
      // filterGalleryItemsByCategory: "all",
    };
  },

  // todo: galeria szuro ujratoltes nem mukodik szolgaltatas valtasnal -- filterGalleryItemsByCategory = undefined
  // watch: {
  //   service(newService) {
  // this.gridSm = this.getGridSm(newService.packages.length, 3, 4);
  // this.excludeServices = [newService.slug];
  // this.filterGalleryItemsByCategory = this.galleryCategories[newService.id];
  // },
  // },

  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.service = this.fetchService();
      }
    );

    this.service = this.fetchService();
    // this.gridSm = this.getGridSm(this.service.packages.length, 3, 4);
    // this.excludeServices = [this.service.slug];
    // this.filterGalleryItemsByCategory = this.galleryCategories[this.service.id];
  },

  methods: {
    forceUpdate() {
      this.$forceUpdate();
    },
    fetchService() {
      let resultService = null;

      this.services.map((service) => {
        if (service.slug === this.$route.params.slug) {
          resultService = service;
        }
      });

      // if (!resultService) {
      //   this.goTo("Services");
      // }

      return resultService;
    },
  },
};
</script>

<style scoped lang="scss"></style>
