<template>
  <SectionBlock>
    <SectionTitle title="Milyen elektromos járművek közül választhatok?" />
    <SectionContent>
      <BRow gutterX="4" gutterY="4" alignH="center" alignV="center">
        <BCol sm="4" v-for="car in cars" :key="car.id">
          <CarCard :car="car" />
        </BCol>
      </BRow>
    </SectionContent>
  </SectionBlock>
</template>
<script>
import SectionBlock from "@/components/layout/sections/parts/SectionBlock";
import SectionTitle from "@/components/layout/sections/parts/SectionTitle";
import SectionContent from "@/components/layout/sections/parts/SectionContent";
import CarCard from "@/components/units/cards/CarCard";

export default {
  name: "SectionCars",
  components: { SectionBlock, SectionTitle, SectionContent, CarCard },
};
</script>

<style scoped lang="scss"></style>
