<template>
  <div class="service-card">
    <div class="service-card-image">
      <img :src="require(`@/assets/services/${service.image}`)" :alt="service.title" />
    </div>
    <div class="service-card-title">{{ service.title }}</div>
    <div class="service-card-content">
      {{ service.excerpt }}
    </div>
    <div class="service-card-action-buttons">
      <BtnMore :to="`/szolgaltatasaink/berles-${service.slug}`" />
    </div>
  </div>
</template>
<script>
import BtnMore from "@/components/units/buttons/BtnMore";

export default {
  name: "ServiceCard",
  components: { BtnMore },
  props: {
    service: Object,
  },
};
</script>

<style scoped lang="scss">
.service-card {
  position: relative;
  display: block;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  text-align: center;
  height: 100%;

  &-image {
    position: relative;
    display: inline-block;
    margin: 0;
    width: 100%;
    height: $card-image-max-height;

    img {
      object-fit: cover;
      object-position: center;
      border-radius: $border-radius $border-radius 0 0;
    }
  }

  &-title {
    position: relative;
    display: block;
    padding: $spacer $spacer 0 $spacer;

    @include set-font-style($font-size-level-5, $font-weight-bold, $gray-900, uppercase);
  }

  &-content {
    position: relative;
    display: block;
    padding: $spacer $spacer 0 $spacer;

    @include set-font-style($font-size-level-4, $font-weight-light);
  }

  &-action-buttons {
    position: relative;
    display: block;
    padding: $spacer;
  }
}
</style>
