<template>
  <SectionBlock>
    <SectionTitle title="Milyen alkalomra bérelne elektromos kisautót?" />
    <SectionContent>
      <BRow gutterX="4" gutterY="4" alignH="center" alignV="stretch">
        <BCol :sm="getGridSm(services.length, 3)" v-for="service in services" :key="service.id">
          <ServiceCard :service="service" />
        </BCol>
      </BRow>
    </SectionContent>
  </SectionBlock>
</template>
<script>
import SectionBlock from "@/components/layout/sections/parts/SectionBlock";
import SectionTitle from "@/components/layout/sections/parts/SectionTitle";
import SectionContent from "@/components/layout/sections/parts/SectionContent";
import ServiceCard from "@/components/units/cards/ServiceCard";

export default {
  name: "SectionServices",
  components: { ServiceCard, SectionContent, SectionTitle, SectionBlock },
  props: {
    excludeServices: {
      type: Array,
      default: null,
    },
  },
  created() {
    this.filterExcludeServices();
  },
  methods: {
    filterExcludeServices() {
      if (!this.excludeServices) return false;

      this.services = this.services.filter((service) => !this.excludeServices.includes(service.slug));
    },
  },
};
</script>

<style scoped lang="scss"></style>
