<template>
  <Btn
    :title="title"
    :to="to"
    :href="href"
    iconRight="arrow-right"
    :variant="variant"
    :size="size"
    :onlyIcon="onlyIcon"
    animation="cylon"
  />
</template>
<script>
import Btn from "@/components/units/buttons/Btn";

export default {
  name: "BtnMore",
  components: { Btn },
  props: {
    title: {
      type: String,
      default: "Bővebben",
    },
    to: {
      type: String,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: "primary",
    },
    size: {
      type: String,
      default: null,
    },
    onlyIcon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss"></style>
