<template>
  <header class="header" :style="{ 'background-image': `url(${require('@/assets/section-banner-bg.jpg')})` }">
    <div class="header-title">
      <h1>{{ title }}</h1>
    </div>
  </header>
</template>
<script>
export default {
  name: "Header",
  components: {},
  props: {
    title: String,
  },
};
</script>
<style scoped lang="scss">
.header {
  background-blend-mode: multiply;
  background-color: rgba($gray-900, 0.7);
  background-attachment: scroll;
  background-size: cover;
  background-position: 100%;
  background-repeat: no-repeat;
  padding: $section-padding 0;
  text-align: center;

  @include media-breakpoint-up(sm) {
    padding: $section-padding * 2 0;
  }

  &-title {
    @include set-headings($font-size-level-6, $font-weight-bold, 0, $white);

    @include media-breakpoint-up(sm) {
      @include set-headings($font-size-level-7, $font-weight-bold, 0, $white);
    }
  }
}
</style>
