<template>
  <div class="service-package-card">
    <div class="service-package-card-title">{{ servicePackage.title }}</div>
    <div class="service-package-card-content">
      <div class="service-package-card-content-price">
        <span>{{ formatPrice(servicePackage.price) }}</span>
        <span v-if="servicePackage.price_is_hourly"> / óra</span>
        <span v-if="servicePackage.price_is_hourly_from"> / órától</span>
      </div>
      <div class="service-package-card-content-deposit" v-if="servicePackage.deposit > 0">
        <span>(+{{ formatPrice(servicePackage.deposit) }} kaució)</span>
      </div>
      <div
        class="service-package-card-content-description"
        v-if="servicePackage.description"
        v-html="servicePackage.description"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicePackageCard",
  props: {
    servicePackage: Object,
  },
};
</script>

<style scoped lang="scss">
.service-package-card {
  position: relative;
  box-shadow: $box-shadow;
  text-align: center;
  border-radius: $border-radius;
  height: 100%;

  &-title {
    padding: $spacer;
    text-transform: uppercase;
    color: $white;
    font-size: $font-size-level-4;
    font-weight: $font-weight-bold;
    background-color: $primary;
    border-radius: $border-radius $border-radius 0 0;

    @include media-breakpoint-up(sm) {
      font-size: $font-size-level-5;
    }
  }

  &-content {
    padding: $spacer;

    &-price {
      color: $primary;
      font-size: $font-size-level-6;
      font-weight: $font-weight-bold;
      line-height: 1;

      @include media-breakpoint-up(sm) {
        font-size: $font-size-level-7;
      }
    }

    &-deposit {
      color: $gray-600;
      font-size: $font-size-level-3;
      font-weight: $font-weight-normal;
    }

    &-description {
      margin-top: $spacer;
    }
  }
}
</style>
